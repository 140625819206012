var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tabs__block"
  }, [_c('ul', {
    staticClass: "tabs"
  }, _vm._l(_vm.tabs, function (i) {
    return _c('li', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !i.hidden,
        expression: "!i.hidden"
      }],
      key: `li:${i.id}`,
      staticClass: "tab app-tab",
      class: {
        'tab-active': _vm.idActiveTab == i.id
      },
      on: {
        "click": function ($event) {
          _vm.idActiveTab = i.id;
        }
      }
    }, [_c('div', {
      staticClass: "tab-title",
      domProps: {
        "innerHTML": _vm._s(i.title)
      }
    }), i.new ? _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": "green"
      }
    }) : _vm._e()], 1);
  }), 0), _vm.$slots.default ? _c('div', {
    staticClass: "right-block"
  }, [_vm._t("default")], 2) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };