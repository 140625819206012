import { getMarketplacesListArray } from "@/utils/enums";
import Warning from "@/components/Alert/Warning";
import WarningAllLK from "@/components/Alert/WarningAllLK";

export default {
    data() {
        const select_marketplace_items = [...getMarketplacesListArray()];

        return {
            select_tokens_items: [],
            hasWb: false,
            hasOzon: false,
            tokens: [],
            select_marketplace_items,
            selectedTokens: this.$store.state.connections.selectedTokens,
            marketplace: this.$store.state.connections.marketplace,
            refreshInterval: null,
            tokensLoading: false,
        };
    },
    created() {
        this.loadTokens();
        this.refreshInterval = setInterval(() => {
            if (this.tokens.length === 0) {
                this.loadTokens();
            } else {
                if (this.refreshInterval) {
                    clearInterval(this.refreshInterval);
                    this.refreshInterval = null;
                }
            }
        }, 5000);
    },
    beforeDestroy() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
            this.refreshInterval = null;
        }
    },
    methods: {
        async marketplaceWatcher() {
            return 0;
        },
        getTableId() {
            return this._uid;
        },
        async loadTokens() {
            this.tokensLoading = true;
            try {
                let tokens = await this.$store.dispatch(
                    "connections/getTokens"
                );
                this.tokens = [];

                tokens?.data.forEach((item) => {
                    if (
                        item.status === 30 &&
                        item.state !== 20 &&
                        item.initial_ready === 1
                    ) {
                        this.tokens.push({
                            id: item.id,
                            title: item.name,
                            datasource: item.datasource,
                        });
                        if (item.datasource === "wb") {
                            this.hasWb = true;
                        }
                        if (item.datasource === "ozon") {
                            this.hasOzon = true;
                        }
                    }
                });
                if (this.tokens.length > 0) {
                    this.chooseTokens();
                }
            } catch (e) {
                console.error(e);
            }
            this.tokensLoading = false;
        },
        chooseTokens() {
            if (!this.hasOzon && this.hasWb) {
                this.marketplace = this.select_marketplace_items[0];
            }
            if (this.hasOzon && !this.hasWb) {
                this.marketplace = this.select_marketplace_items[1];
            }

            this.select_tokens_items = [
                ...this.tokens.filter(
                    (item) => item.datasource === this.currentDatasource
                ),
            ];
            if (this.$store.state.connections.selectedTokens?.length === 0) {
                this.selectedTokens = this.allMarketplaceTokens;
            } else {
                this.selectedTokens =
                    this.$store.state.connections.selectedTokens;
            }
        },
        changeTokens() {
            this.select_tokens_items = [
                ...this.tokens.filter(
                    (item) => item.datasource === this.currentDatasource
                ),
            ];
            this.selectedTokens = this.allMarketplaceTokens;
        },
    },
    computed: {
        loading() {
            return (
                !this.$store.state.connections.hasInitialReadyTokens &&
                this.$store.state.connections.hasValidTokens
            );
        },
        currentDatasource() {
            if (this.marketplace.title === "OZON") {
                return "ozon";
            }
            if (this.marketplace.title === "Wildberries") {
                return "wb";
            }
        },
        allMarketplaceTokens() {
            return [
                ...this.tokens.filter(
                    (item) => item.datasource === this.currentDatasource
                ),
            ];
        },
    },
    watch: {
        marketplace: {
            async handler(newValue, oldValue) {
                if (oldValue) {
                    this.changeTokens();
                    localStorage.setItem(
                        "mp",
                        JSON.stringify(this.marketplace)
                    );
                    this.$store.dispatch(
                        "connections/setMarketplace",
                        this.marketplace
                    );
                    if (newValue.id !== oldValue.id) {
                        await this.marketplaceWatcher();
                        this.$nextTick(() => {
                            this.changeTokens();
                        });
                    }
                }
            },
            immediate: true,
        },
        selectedTokens(val) {
            this.$store.dispatch("connections/setSelectedTokens", val);
        },
    },
    components: {
        Warning,
        WarningAllLK,
    },
};
