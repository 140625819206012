export default {
    methods: {
        async loadQueries(query, productId) {
            let params = {};
            if (query) {
                params = query;
            }
            if (productId) {
                params.productId = productId;
            }
            return await this.$store.dispatch("seo/getMonitoringQueries", {
                ...params,
                ...this.reportSettings,
            });
        },
        // setDatesAndPositions(oneQueryItems, columnsDates, datesAndPositions) {
        //     oneQueryItems.forEach((oneQuery) => {
        //         columnsDates.push(oneQuery.snapshotDate);
        //         datesAndPositions[oneQuery.snapshotDate] = oneQuery.position;
        //     });
        // },
        // removeDuplicatesDates(columnsDates) {
        //     return columnsDates.filter(
        //         (item, index) => columnsDates.indexOf(item) === index
        //     );
        // },
        formatDate(date) {
            const split = date.split("-");
            return split[2] + "-" + split[0] + "-" + split[1];
        },
    },
    computed: {
        date1() {
            return this.formatDate(this.reportSettings.date_from);
        },
        date2() {
            return this.formatDate(this.reportSettings.date_to);
        },
    },
};
