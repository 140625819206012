var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('vue-custom-scrollbar', {
    staticClass: "cell-list",
    class: [`gap-${_vm.gap}`],
    attrs: {
      "settings": _vm.scrollSettings
    }
  }, [_vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };