var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "loading"
  }, [_c('h2', {
    staticClass: "loading__title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "loading__img__wrap"
  }, [_c('indicator', {
    staticClass: "loader"
  }), _c('img', {
    staticClass: "loading__img",
    attrs: {
      "src": _vm.image,
      "alt": ""
    }
  })], 1), _c('p', {
    staticClass: "loading__text"
  }, [_vm._v(" " + _vm._s(_vm.text1) + " ")]), _c('p', {
    staticClass: "loading__text"
  }, [_vm._v(" " + _vm._s(_vm.text2) + " ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };