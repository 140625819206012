var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('portal-target', {
    attrs: {
      "name": `__dt-actions-${_vm.id}`
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };