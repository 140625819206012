import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { ModalMixin } from "@/components/modals/layouts/Default.vue";
import AppTabs from "@/components/AppTabs.vue";
import LinedTextarea from "@/components/LinedTextarea.vue";
import Tooltip from "@/components/Tooltip";
import ProjectModals from "@/mixins/ProjectModals.js";
export default {
  mixins: [ModalMixin, ProjectModals],
  props: {
    id: {
      required: false
    }
  },
  data() {
    const tabs = [{
      id: 1,
      title: "Название"
    }, {
      id: 2,
      title: "Запросы"
    }, {
      id: 3,
      title: "Товары"
    }
    // {
    //     id: 4,
    //     title: "Товары конкурентов",
    // },
    ];
    const select_statuses_items = [{
      id: 10,
      title: "Черновик"
    }, {
      id: 20,
      title: "Работает"
    }];
    const initial_new_project = {
      name: "",
      datasource: ""
    };
    return {
      select_statuses_items,
      initial_new_project,
      new_project: {
        ...initial_new_project
      },
      tabs,
      status: select_statuses_items[0],
      projectId: "",
      tabs_model: tabs[0],
      loading: false,
      newQueries: "",
      newProducts: "",
      newProducts2: ""
    };
  },
  mounted() {},
  methods: {
    async onOpen({
      item,
      onComplete
    } = {}) {
      await this.$store.dispatch("seo/getQueriesStats");
      this.onComplete = onComplete;
      this.projectId = item.id;
      this.new_project = {
        name: item.name,
        datasource: item.datasource
      };
      this.status = this.select_statuses_items.find(i => {
        return i.id === item.status;
      });
      this.tabs_model = this.tabs[0];
      this.cleanFields();
      this.newQueries = this.empty;
      this.newProducts = this.empty;
      const queries = await this.$store.dispatch("seo/getProjectQueries", {
        id: this.projectId
      });
      const products = await this.$store.dispatch("seo/getProjectProducts", {
        id: this.projectId,
        own: 1
      });
      const products2 = await this.$store.dispatch("seo/getProjectProducts", {
        id: this.projectId,
        own: 0
      });
      queries.data.forEach(item => {
        this.queries += item.query + "\n";
      });
      products.data.forEach(item => {
        this.products += item.sku + "\n";
      });
      products2.data.forEach(item => {
        this.products2 += item.sku + "\n";
      });
    },
    newQueriesImport(queries) {
      queries.forEach(row => {
        this.newQueries += row + "\n";
      });
    },
    newProductsImport(products) {
      products.forEach(row => {
        this.newProducts += row + "\n";
      });
    },
    newProducts2Import(products2) {
      products2.forEach(row => {
        this.newProducts2 += row + "\n";
      });
    },
    cleanQuery(queryNumber) {
      let end = "\n";
      if (queryNumber === this.queriesArray.length) {
        end = "";
      }
      this.queries = this.queries.replace(this.queriesArray[queryNumber - 1] + end, "");
    },
    cleanProduct(productNumber) {
      let end = "\n";
      if (productNumber === this.productsArray.length) {
        end = "";
      }
      this.products = this.products.replace(this.productsArray[productNumber - 1] + end, "");
    },
    cleanProduct2(product2Number) {
      let end = "\n";
      if (product2Number === this.products2Array.length) {
        end = "";
      }
      this.products2 = this.products2.replace(this.products2Array[product2Number - 1] + end, "");
    },
    async submit({
      close
    }) {
      this.loading = true;
      try {
        const result = await this.$store.dispatch("seo/updateProjects", {
          id: this.projectId,
          name: this.new_project.name,
          datasource: this.new_project.datasource,
          status: this.status.id
        });
        await this.uploadQueriesAndProducts();
        this.loading = false;
        close();
        this.onComplete && this.onComplete(result);
        this.$emit("complete", result);
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    }
  },
  computed: {
    queriesArray() {
      return this.allQueries.split("\n").filter(item => item !== "");
    },
    productsArray() {
      return this.allProducts.split("\n").filter(item => item !== "");
    },
    products2Array() {
      return this.allProducts2.split("\n").filter(item => item !== "");
    },
    valid1() {
      var _this$new_project$nam;
      return (_this$new_project$nam = this.new_project.name) === null || _this$new_project$nam === void 0 ? void 0 : _this$new_project$nam.length;
    },
    valid2() {
      return this.checkNonEmptyLines(this.allQueries) > 0 && this.checkNonEmptyLines(this.allProducts) > 0;
    },
    emptyLines() {
      const lines = this.availableQueries - this.checkNonEmptyLines(this.newQueries);
      return lines > 0 ? lines : 0;
    },
    allQueries() {
      return this.queries + "\n" + this.newQueries;
    },
    allProducts() {
      return this.products + "\n" + this.newProducts;
    },
    allProducts2() {
      return this.products2 + "\n" + this.newProducts2;
    },
    actions() {
      return [{
        name: "cancel",
        text: "Отмена",
        class: "btn-outline",
        action: ({
          close
        }) => {
          close();
        }
      }, {
        name: "submit",
        text: "Сохранить",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        loading: this.loading,
        disabled: !this.validLast
      }];
    }
  },
  components: {
    LinedTextarea,
    AppTabs,
    Tooltip
  }
};