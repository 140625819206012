var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "lists-page__none",
    class: {
      small: _vm.small
    }
  }, [!_vm.small ? _c('div', {
    staticClass: "lists-page__none-title"
  }, [_vm._v(" Мониторинг позиций ")]) : _vm._e(), _vm.small ? _c('img', {
    staticClass: "loading__img",
    attrs: {
      "src": require('@/assets/images/wireframes/monitoring-wireframe.png')
    }
  }) : _vm._e(), _vm.small ? _c('div', {
    staticClass: "lists-page__none-text",
    class: {
      small: _vm.small
    }
  }, [_vm._v(" " + _vm._s(this.text) + " ")]) : _c('div', {
    staticClass: "lists-page__none-text"
  }, [_vm._v(" В этом разделе вы можете настроить ежедневный мониторинг позиций своих товаров "), _c('br'), _vm._v("(а также конкурентов) по заданному набору ключевых слов. "), _c('br'), _vm._v("Мониторинг работает для Wildberries и OZON ")]), _c('button', {
    staticClass: "btn-outline btn-width-auto",
    class: {
      'connect-btn': _vm.small
    },
    on: {
      "click": _vm.onCreateProject
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/plus.svg`)
    }
  }), _vm._v(" Запустить мониторинг ")]), _c('modal-add-project', {
    ref: "modalAddProject",
    on: {
      "complete": _vm.onAdded
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };