var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', _vm._g({
    staticClass: "cell",
    class: [_vm.$store.getters.themeClass === 'dark' ? 'dark' : 'light', _vm.color]
  }, _vm.$listeners), [_vm.title ? [_c('div', {
    staticClass: "cell__title two-lines"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  })]), _c('div', {
    staticClass: "spacer"
  })] : _vm._e(), _vm._l(_vm.items, function (item, i) {
    return [item.title && i > 0 ? _c('div', {
      key: `s:${i}`,
      staticClass: "spacer"
    }) : _vm._e(), _c('div', {
      key: i,
      staticClass: "item"
    }, [item.title ? _c('div', {
      key: `t:${i}`,
      staticClass: "cell__title"
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(item.title)
      }
    })]) : _vm._e(), _c('div', {
      key: `v:${i}`,
      staticClass: "cell__info"
    }, [_vm._v(" " + _vm._s(_vm.format(item.info)) + " "), item.infoMin ? _c('span', {
      staticClass: "cell__info-min"
    }, [_vm._v(" " + _vm._s(item.infoMin) + " ")]) : _vm._e()])])];
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };