var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "loading-overlay"
  }, [_c('div', {
    staticClass: "loading"
  }, [_vm.$slots.default ? _c('div', {
    staticClass: "loading-text"
  }, [_vm._t("default")], 2) : _c('loading-indicator')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };