var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tabs__block"
  }, [_c('ul', {
    staticClass: "tabs"
  }, _vm._l(_vm.tabs, function (i) {
    return _c('li', {
      key: `li:${i.id}`,
      staticClass: "tab",
      class: {
        'tab-active': _vm.idActiveTab >= i.id
      }
    }, [_vm.idActiveTab > i.id ? _c('img', {
      staticClass: "tab__checkmark",
      attrs: {
        "src": require(`@/assets/images/icons/checkmark.svg`),
        "alt": "checkmark"
      }
    }) : _vm._e(), _vm.idActiveTab === i.id ? _c('div', {
      staticClass: "tab__dot"
    }) : _vm._e(), _vm._v(" " + _vm._s(i.title) + " ")]);
  }), 0), _vm.$slots.default ? _c('div', {
    staticClass: "right-block"
  }, [_vm._t("default")], 2) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };