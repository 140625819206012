var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('line-chart', {
    key: _vm.refresh,
    attrs: {
      "chartData": _vm.chartData,
      "options": _vm.chartOptions,
      "height": 350
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };