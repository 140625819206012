var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('transition', [false ? _c('div', {
    staticClass: "warning"
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/warning.svg`),
      "alt": "Warning"
    }
  }), _c('p', {
    staticClass: "warning__text"
  }, [_vm._t("default", function () {
    return [_vm._v(" Наблюдается сбой на стороне API Wildberries, поступают не полные данные. Мы в курсе проблемы и следим за ситуацией ")];
  })], 2)]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };