var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('transition', [_c('div', {
    staticClass: "warning danger"
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/warning.svg`),
      "alt": "Warning"
    }
  }), _c('p', {
    staticClass: "warning__text"
  }, [_vm._t("default")], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };