import "core-js/modules/esnext.iterator.map.js";
import LineChart from "@/components/charts/LineChart2.vue";
export default {
  props: {
    dataset: {
      type: Array,
      required: true
    },
    color: {
      type: String,
      default: "#6D8A9F"
    },
    reverse: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    chartData() {
      this.refresh++;
    }
  },
  data() {
    return {
      refresh: 0,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
          yAxes: [{
            ticks: {
              display: false
            },
            gridLines: {
              tickMarkLength: 0,
              display: false
            }
          }],
          xAxes: [{
            ticks: {
              display: false
            },
            gridLines: {
              display: false
            }
          }]
        },
        tooltips: {
          enabled: false
        },
        layout: {
          padding: {
            top: 8
          }
        }
      }
    };
  },
  components: {
    LineChart
  },
  mounted() {},
  computed: {
    chartData() {
      let chart = this.dataset;
      if (this.reverse) {
        // данные могут приходит в формате '1 234' : исправим на int
        chart = chart.map(i => parseInt(i.toString().replace(/ /g, "")));
        let max = Math.max(...chart);
        ////chart = chart.map(i => max - i) //@V
        chart = chart.map(i => i < 0 ? i : max - i); //выколотые точки на графике
      }
      return {
        labels: chart.map((data, i) => i + 1),
        datasets: [{
          borderColor: this.color,
          borderWidth: 2,
          pointBorderColor: "transparent",
          backgroundColor: "smallGradient",
          pointRadius: 0,
          data: chart
        }]
      };
    }
  }
};