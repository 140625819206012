import Modal from "@/components/modals/layouts/Default";
import LinedTextarea from "@/components/LinedTextarea";
import AppSelect from "@/components/AppSelect";
import ImportBtn from "@/components/Seo/ImportBtn";

export default {
    data() {
        let empty = "";
        return {
            queries: empty,
            products: empty,
            products2: empty,
            empty,
            height: 350,
            resize: "none",
            width: 450,
        };
    },
    methods: {
        otherProductsImport(products) {
            products.forEach((row) => {
                this.products2 += row + "\n";
            });
        },
        cleanFields() {
            this.queries = this.empty;
            this.products = this.empty;
            this.products2 = this.empty;
        },
        async uploadQueriesAndProducts() {
            await this.uploadQueries(this.queriesArray);
            await this.uploadProducts(this.productsArray);
            await this.uploadProducts2(this.products2Array);
        },
        async uploadQueries(queriesArray) {
            await this.$store.dispatch("seo/replaceProjectQueries", {
                id: this.projectId,
                queries: queriesArray,
            });
        },
        async uploadProducts(productsArray) {
            await this.$store.dispatch("seo/replaceProjectProducts", {
                id: this.projectId,
                products: productsArray,
                own: 1,
            });
        },
        async uploadProducts2(products2Array) {
            await this.$store.dispatch("seo/replaceProjectProducts", {
                id: this.projectId,
                products: products2Array.length > 0 ? products2Array : [""],
                own: 0,
            });
        },
        checkNonEmptyLines(content) {
            return (content.match(/^\s*\S/gm) || "").length;
        },
    },
    computed: {
        validLast() {
            return this.valid1 && this.valid2;
        },
        textareaStyles() {
            return {
                height: this.height + "px",
                width: "100%",
                resize: this.resize,
            };
        },
        availableQueries() {
            return (
                this.$store.state.seo.queriesLimit -
                this.$store.state.seo.currentQueriesCount
            );
        },
    },
    components: {
        Modal,
        LinedTextarea,
        AppSelect,
        ImportBtn,
    },
};
