var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', {
    staticClass: "tooltip"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/images/icons/tooltip.svg"),
      "alt": "Tooltip"
    }
  }), _c('p', {
    staticClass: "tooltip__content",
    class: _vm.position
  }, [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };