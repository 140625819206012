var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.chartData ? _c('line-chart', {
    key: _vm.refresh,
    staticClass: "chart",
    attrs: {
      "chartData": _vm.chartData,
      "options": _vm.chartOptions,
      "width": 250,
      "height": 64
    }
  }) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };