var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', _vm._l(_vm.productsData, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "product-block-wrap"
    }, [item.loading ? _c('indicator', {
      staticClass: "loader"
    }) : _c('div', [_c('div', {
      staticClass: "product-block"
    }, [_c('img', {
      staticClass: "product-block__image",
      attrs: {
        "src": item.image !== undefined ? item.image : require('../../assets/images/img.svg'),
        "alt": "Image"
      }
    }), _c('div', {
      staticClass: "product-block__content"
    }, [_c('div', {
      staticClass: "product-block__item"
    }, [item.title === '&:NA' ? [_c('span', {
      staticClass: "gray"
    }, [_vm._v(" — ")])] : _c('a', {
      attrs: {
        "href": `/${item.mp}/product/${item.p_id}/info/`,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")])], 2), _c('div', {
      staticClass: "product-block__item"
    }, [_c('span', [_vm._v("SKU:")]), !item.mp_url ? [_c('span', {
      staticClass: "gray"
    }, [_vm._v(" " + _vm._s(item.sku) + " ")])] : _c('a', {
      attrs: {
        "href": item.mp_url,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(item.sku) + " ")])], 2), _c('div', {
      staticClass: "product-block__item"
    }, [_c('span', [_vm._v("Бренд:")]), item.brand === '&:NA' ? [_c('span', {
      staticClass: "gray"
    }, [_vm._v(" — ")])] : _c('a', {
      attrs: {
        "href": `/${item.mp}/brand/${item.b_id}/info`,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(item.brand) + " ")])], 2), _c('div', {
      staticClass: "product-block__item"
    }, [_c('span', [_vm._v("Продавец:")]), item.seller === '&:NA' ? [_c('span', {
      staticClass: "gray"
    }, [_vm._v(" — ")])] : ['Ozon', 'ООО ВАЙЛДБЕРРИЗ', 'ВАЙЛДБЕРРИЗ ООО'].includes(item.seller) ? [_c('span', {
      staticClass: "gray",
      domProps: {
        "innerHTML": _vm._s(item.seller)
      }
    })] : _c('a', {
      attrs: {
        "href": `/${item.mp}/seller/${item.s_id}/info/`,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(item.seller) + " ")])], 2)]), _c('div', {
      staticClass: "table-filters",
      staticStyle: {
        "margin-left": "auto",
        "display": "flex"
      }
    }, [_c('data-table-actions', {
      attrs: {
        "id": index + 1
      }
    })], 1)]), _vm.reportSettings ? _c('Project-list-table', {
      attrs: {
        "id": index + 1,
        "productId": item.p_id,
        "report-settings": _vm.reportSettings,
        "paginatable": true,
        "configurable": true,
        "stickyColumns": 3
      }
    }) : _vm._e()], 1)], 1);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };