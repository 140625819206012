import colors from "@/utils/colors";
import numbers from "@/utils/numbers";
export default {
  props: ["items", "color", "title"],
  data() {
    return {
      colors
    };
  },
  mounted() {},
  computed: {},
  methods: {
    format(number) {
      if (typeof number !== "number") {
        return number;
      }

      //!&: 2022-02-22
      if (number == -1) {
        number = 0;
      }
      return numbers.format(number, "ru", 1000000);
    }
  }
};