import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import DataTable from "@/components/DataTable/DataTable";
import KeywordsPositionsChart from "@/components/Product/KeywordsPositionsChart";
import PositionMonitoringTablesMixin from "@/mixins/PositionMonitoringTables";
import { dateFormat } from "@/utils/dates";
export default {
  mixins: [PositionMonitoringTablesMixin],
  components: {
    DataTable,
    KeywordsPositionsChart
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    productId: {
      type: Number,
      required: false
    },
    stickyColumns: {
      type: Number,
      required: false
    },
    hasSkuColumn: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    let defaultColumns = [{
      title: "Запрос",
      show: true,
      name: "query",
      type: "text",
      width: 300,
      filter: "text",
      position: 2
    }, {
      title: "Частотность",
      show: true,
      name: "shows",
      type: "number",
      width: 140,
      filter: "numberRange",
      position: 3
    }, {
      title: "Результатов по запросу",
      show: true,
      name: "tot_keyword_products",
      type: "number",
      width: 200,
      filter: "numberRange",
      position: 4
    }, {
      title: "Средняя позиция",
      show: true,
      name: "avg_position",
      type: "number",
      width: 200,
      filter: "numberRange",
      position: 5
    }, {
      title: "Динамика позиций",
      show: true,
      name: "dynamic",
      type: "slot",
      width: 220,
      position: 6,
      sortable: false
    }];
    if (this.hasSkuColumn) {
      defaultColumns = [{
        title: "SKU",
        show: true,
        name: "p_id",
        type: "text",
        width: 120,
        filter: "text",
        position: 1
      }, ...defaultColumns];
    }
    return {
      defaultColumns,
      queries: [],
      columnsDates: []
    };
  },
  methods: {
    async tableLoader({
      query
    }) {
      const result = await this.loadQueries(query, this.productId);
      const tableData = result.data;
      let columnsDates = [];
      Object.keys(tableData[0]).forEach(key => {
        if (key.startsWith("20")) {
          columnsDates.push(key);
        }
      });
      columnsDates.sort();
      if (this.reportSettings.deskDatesOrder) {
        columnsDates.reverse();
      }
      this.columnsDates = columnsDates;
      if (query.action === "export") {
        let data = tableData;
        const titleNames = this.columns.map(columnRaw => {
          const {
            name,
            title
          } = columnRaw;
          return {
            name,
            title
          };
        });
        let option = {};
        const dataset = data.map(el => {
          let object = {};
          titleNames.forEach(item => {
            object[item.name] = el[item.name];
          });
          return object;
        });
        option.type = "excel";
        option.datas = [{
          sheetData: dataset,
          sheetName: "Page 1",
          sheetFilter: Object.keys(dataset[0]),
          sheetHeader: titleNames.map(item => item.title)
        }];
        return option;
      } else {
        return {
          items: tableData,
          total: result.total
        };
      }
    }
  },
  computed: {
    columns() {
      const dynamicColumns = [];
      this.columnsDates.forEach((item, index) => {
        dynamicColumns.push({
          title: dateFormat(item),
          show: true,
          name: item,
          type: "text",
          width: 120,
          position: index + 7,
          noSettings: true
        });
      });
      return [...this.defaultColumns, ...dynamicColumns];
    }
  }
};