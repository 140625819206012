var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Создание и настройка проекта",
      "width": 900,
      "actions": _vm.actions
    }
  }, [_c('app-steps', {
    attrs: {
      "tabs": _vm.tabs
    },
    model: {
      value: _vm.tabs_model,
      callback: function ($$v) {
        _vm.tabs_model = $$v;
      },
      expression: "tabs_model"
    }
  }), _vm.tabs_model.id === 1 ? _c('div', {
    staticClass: "modal__tab__content"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "modal-item name"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Название проекта")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_project.name,
      expression: "new_project.name"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": "Название проекта",
      "autofocus": ""
    },
    domProps: {
      "value": _vm.new_project.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.new_project, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "modal-item marketplace"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Маркетплейс")]), _c('app-select', {
    attrs: {
      "items": _vm.select_marketplace_items,
      "fullwidth": "",
      "disabled": !!_vm.mp
    },
    model: {
      value: _vm.new_project.datasource,
      callback: function ($$v) {
        _vm.$set(_vm.new_project, "datasource", $$v);
      },
      expression: "new_project.datasource"
    }
  })], 1)])]) : _vm._e(), _vm.tabs_model.id === 2 ? _c('div', {
    staticClass: "modal__tab__content"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "modal-item requests",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v(" Запросы для мониторинга "), _c('Tooltip', {
    attrs: {
      "position": "bottom right"
    }
  }, [_vm._v(" Введите запросы (ключевые слова) для мониторинга, по одному в строке. Вы также можете импортировать список запросов из файла XLSX ")]), _c('span', {
    staticClass: "lines",
    class: {
      red: _vm.emptyLines <= 0
    }
  }, [_vm._v(" Доступно " + _vm._s(_vm.emptyLines) + " из " + _vm._s(_vm.$store.state.seo.queriesLimit) + " "), _vm.emptyLines <= 0 ? _c('span', [_vm._v(" Исчерпан лимит запросов на вашем тарифе ")]) : _vm._e()])], 1), _c('lined-textarea', {
    attrs: {
      "styles": _vm.textareaStyles,
      "limit-number": _vm.availableQueries,
      "placeholder": "Введите запросы, по одному в строке, например:\nкроссовки женские\nсиние кеды\nлетние кроссовки"
    },
    model: {
      value: _vm.queries,
      callback: function ($$v) {
        _vm.queries = $$v;
      },
      expression: "queries"
    }
  }), _c('Import-btn', {
    attrs: {
      "tooltip": "Формат файла XLSX или XLS, запросы по одному в строке в одной колонке",
      "tooltip-classes": "top right"
    },
    on: {
      "imported": _vm.queriesImport
    }
  })], 1), _c('div', {
    staticClass: "modal-item products",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v(" Список товаров "), _c('Tooltip', {
    attrs: {
      "position": "bottom left"
    }
  }, [_vm._v(" Укажите товар или несколько товаров, позиции которых вы хотите отслеживать. По одному в строке, артикул товара (SKU) или ссылку (URL) товара из маркетплейса. Вы также можете импортировать список товаров из файла XLSX ")])], 1), _c('lined-textarea', {
    attrs: {
      "styles": _vm.textareaStyles,
      "placeholder": "Введите номер SKU или ссылку на товар, по одному в строке, например:\n2389212\n6559211"
    },
    model: {
      value: _vm.products,
      callback: function ($$v) {
        _vm.products = $$v;
      },
      expression: "products"
    }
  }), _c('Import-btn', {
    attrs: {
      "tooltip": "Формат файла XLSX или XLS, артикулы товаров или ссылки по одному в строке в одной колонке",
      "tooltip-classes": "top left"
    },
    on: {
      "imported": _vm.yourProductsImport
    }
  })], 1)])]) : _vm._e(), _vm.tabs_model.id === 4 ? _c('div', {
    staticClass: "modal__tab__content"
  }, [_c('div', {
    staticClass: "column"
  }, [_c('p', {
    staticClass: "text"
  }, [_vm._v(" Все готово. Проверьте настройки проекта, если все в порядке - запускаем! ")]), _c('div', {
    staticClass: "result__item"
  }, [_c('span', {
    staticClass: "item__text"
  }, [_vm._v("Название проекта:")]), _c('span', {
    staticClass: "item__value"
  }, [_vm._v(_vm._s(_vm.new_project.name))])]), _c('div', {
    staticClass: "result__item"
  }, [_c('span', {
    staticClass: "item__text"
  }, [_vm._v("Маркетплейс:")]), _c('span', {
    staticClass: "item__value"
  }, [_vm._v(_vm._s(_vm.new_project.datasource.title))])]), _c('div', {
    staticClass: "result__item"
  }, [_c('span', {
    staticClass: "item__text"
  }, [_vm._v("Запросов:")]), _c('span', {
    staticClass: "item__value"
  }, [_vm._v(_vm._s(this.checkNonEmptyLines(this.queries)))])]), _c('div', {
    staticClass: "result__item"
  }, [_c('span', {
    staticClass: "item__text"
  }, [_vm._v("Товаров:")]), _c('span', {
    staticClass: "item__value"
  }, [_vm._v(_vm._s(this.checkNonEmptyLines(this.products)))])])])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };