export default {
  props: {
    position: {
      validator(value) {
        return ["top", "bottom", "top left", "top right", "bottom right", "bottom left"].includes(value);
      },
      default: "top right"
    }
  },
  name: "Tooltip"
};