var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.columns ? _c('div', [_c('data-table', _vm._b({
    attrs: {
      "table-id": "project_list",
      "id": _vm.id,
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "selectable": false,
      "paginatable": true,
      "stickyColumns": _vm.stickyColumns,
      "item-id": "id"
    },
    scopedSlots: _vm._u([_vm.date1 && _vm.date2 ? {
      key: "dynamic",
      fn: function ({
        item
      }) {
        return [_c('keywords-positions-chart', {
          attrs: {
            "date": _vm.date1,
            "date2": _vm.date2,
            "item": item
          }
        })];
      }
    } : null], null, true)
  }, 'data-table', _vm.$attrs, false))], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };