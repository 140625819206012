var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Редактирование проекта",
      "width": 900,
      "actions": _vm.actions
    }
  }, [_c('app-tabs', {
    staticStyle: {
      "margin-top": "-20px"
    },
    attrs: {
      "tabs": _vm.tabs
    },
    model: {
      value: _vm.tabs_model,
      callback: function ($$v) {
        _vm.tabs_model = $$v;
      },
      expression: "tabs_model"
    }
  }), _vm.tabs_model.id === 1 ? _c('div', {
    staticClass: "modal__tab__content"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "modal-item name"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Название проекта")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_project.name,
      expression: "new_project.name"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": "Название проекта",
      "autofocus": ""
    },
    domProps: {
      "value": _vm.new_project.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.new_project, "name", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _vm.tabs_model.id === 2 ? _c('div', {
    staticClass: "modal__tab__content"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "modal-item requests",
    staticStyle: {
      "width": "100%",
      "margin-right": "20px"
    }
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v(" Запросы для мониторинга "), _c('Tooltip', {
    attrs: {
      "position": "bottom right"
    }
  }, [_vm._v(" Текущий рабочий список запросов. Вы можете удалить запросы из проекта ")])], 1), _c('lined-textarea', {
    attrs: {
      "readonly": true,
      "styles": _vm.textareaStyles,
      "clearLines": true
    },
    on: {
      "clearLine": _vm.cleanQuery
    },
    model: {
      value: _vm.queries,
      callback: function ($$v) {
        _vm.queries = $$v;
      },
      expression: "queries"
    }
  })], 1), _c('div', {
    staticClass: "modal-item requests",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v(" Добавить новые запросы "), _c('Tooltip', {
    attrs: {
      "position": "bottom left"
    }
  }, [_vm._v(" Введите новые запросы, которые хотите добавить в проект, по одному в строке. Или импортируйте список из файла XLSX ")]), _c('span', {
    staticClass: "lines",
    class: {
      red: _vm.emptyLines <= 0
    }
  }, [_vm._v(" Доступно " + _vm._s(_vm.emptyLines) + " из " + _vm._s(_vm.$store.state.seo.queriesLimit) + " "), _vm.emptyLines <= 0 ? _c('span', [_vm._v(" Исчерпан лимит запросов на вашем тарифе ")]) : _vm._e()])], 1), _c('lined-textarea', {
    attrs: {
      "styles": _vm.textareaStyles,
      "limit-number": _vm.availableQueries,
      "placeholder": "Введите запросы, по одному в строке, например:\nкроссовки женские\nсиние кеды\nлетние кроссовки"
    },
    model: {
      value: _vm.newQueries,
      callback: function ($$v) {
        _vm.newQueries = $$v;
      },
      expression: "newQueries"
    }
  }), _c('Import-btn', {
    attrs: {
      "tooltip": "Формат файла XLSX или XLS, запросы по одному в строке в одной колонке",
      "tooltip-classes": "top left"
    },
    on: {
      "imported": _vm.newQueriesImport
    }
  })], 1)])]) : _vm._e(), _vm.tabs_model.id === 3 ? _c('div', {
    staticClass: "modal__tab__content"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "modal-item products",
    staticStyle: {
      "width": "100%",
      "margin-right": "20px"
    }
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v(" Товары в проекте "), _c('Tooltip', {
    attrs: {
      "position": "bottom right"
    }
  }, [_vm._v(" Текущий список товаров, по которым отслеживаются позиции. Вы можете удалить неактуальные товары, но в проекте должен остаться как минимум 1 товар ")])], 1), _c('lined-textarea', {
    attrs: {
      "readonly": true,
      "styles": _vm.textareaStyles,
      "clearLines": true
    },
    on: {
      "clearLine": _vm.cleanProduct
    },
    model: {
      value: _vm.products,
      callback: function ($$v) {
        _vm.products = $$v;
      },
      expression: "products"
    }
  })], 1), _c('div', {
    staticClass: "modal-item products",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v(" Добавить новые товары "), _c('Tooltip', {
    attrs: {
      "position": "bottom left"
    }
  }, [_vm._v(" Добавьте новые товары для отслеживания позиций, по одному в строке, номер SKU или ссылка на товар. Или импортируйте список из файла XLSX ")])], 1), _c('lined-textarea', {
    attrs: {
      "styles": _vm.textareaStyles,
      "placeholder": "Введите номер SKU или ссылку на товар, по одному в строке, например:\n2389212\n6559211"
    },
    model: {
      value: _vm.newProducts,
      callback: function ($$v) {
        _vm.newProducts = $$v;
      },
      expression: "newProducts"
    }
  }), _c('Import-btn', {
    attrs: {
      "tooltip": "Формат файла XLSX или XLS, артикулы товаров или ссылки по одному в строке в одной колонке",
      "tooltip-classes": "top left"
    },
    on: {
      "imported": _vm.newProductsImport
    }
  })], 1)])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };