var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "main-title__wrapper"
  }, [_c('h1', {
    staticClass: "main-title",
    class: {
      noDark: _vm.noDark
    }
  }, [_c('div', [_vm._t("default")], 2), _vm.$slots.action ? _c('div', {
    staticClass: "action"
  }, [_vm._t("action")], 2) : _vm._e()]), _vm.$slots.right ? _c('div', [_vm._t("right")], 2) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };