import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { ModalMixin } from "@/components/modals/layouts/Default.vue";
import AppSteps from "@/components/AppSteps.vue";
import LinedTextarea from "@/components/LinedTextarea.vue";
import Tooltip from "@/components/Tooltip";
import { getMarketplacesListArray } from "@/utils/enums";
import ProjectModals from "@/mixins/ProjectModals.js";
export default {
  mixins: [ModalMixin, ProjectModals],
  props: {
    id: {
      required: false
    }
  },
  data() {
    const tabs = [{
      id: 1,
      title: "Название и маркетплейс"
    }, {
      id: 2,
      title: "Запросы и товары"
    },
    // {
    //     id: 3,
    //     title: "Товары конкурентов",
    // },
    {
      id: 4,
      title: "Запуск"
    }];
    const select_marketplace_items = [...getMarketplacesListArray()];
    const initial_new_project = {
      name: "",
      datasource: select_marketplace_items[0]
    };
    return {
      initial_new_project,
      new_project: {
        ...initial_new_project
      },
      mp: null,
      select_marketplace_items,
      tabs,
      tabs_model: tabs[0],
      loading: false,
      actions: [{
        name: "cancel",
        text: "Назад",
        class: "btn-outline",
        action: ({
          close
        }) => {
          close();
        }
      }, {
        name: "submit",
        text: "Далее",
        action: () => {
          this.tabs_model = tabs[this.tabs_model.id];
        },
        loading: this.loading,
        disabled: true
      }]
    };
  },
  mounted() {},
  methods: {
    async onOpen({
      mp = null
    } = {}) {
      await this.$store.dispatch("seo/getQueriesStats");
      this.new_project = {
        ...this.initial_new_project
      };
      this.mp = mp;
      if (mp) {
        this.new_project.datasource = this.select_marketplace_items.find(i => i.id === mp);
      }
      this.tabs_model = this.tabs[0];
      this.cleanFields();
    },
    queriesImport(queries) {
      queries.forEach(row => {
        this.queries += row + "\n";
      });
    },
    yourProductsImport(products) {
      products.forEach(row => {
        this.products += row + "\n";
      });
    },
    async submit({
      close
    }) {
      this.loading = true;
      try {
        var _this$new_project$dat, _this$new_project$dat2;
        const result = await this.$store.dispatch("seo/createProject", {
          name: this.new_project.name,
          datasource: (_this$new_project$dat = this.new_project.datasource) === null || _this$new_project$dat === void 0 ? void 0 : _this$new_project$dat.id
        });
        this.projectId = result === null || result === void 0 ? void 0 : result.data[0].id;
        await this.$store.dispatch("seo/updateProjects", {
          id: this.projectId,
          name: this.new_project.name,
          datasource: (_this$new_project$dat2 = this.new_project.datasource) === null || _this$new_project$dat2 === void 0 ? void 0 : _this$new_project$dat2.id,
          status: 20
        });
        await this.uploadQueriesAndProducts();
        this.loading = false;
        this.$store.commit("seo/SET_HAS_PROJECTS", true);
        close();
        this.$emit("complete", result);
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    }
  },
  computed: {
    queriesArray() {
      return this.queries.split("\n").filter(item => item !== "");
    },
    productsArray() {
      return this.products.split("\n").filter(item => item !== "");
    },
    products2Array() {
      return this.products2.split("\n").filter(item => item !== "");
    },
    valid1() {
      var _this$new_project$nam, _this$new_project$dat3;
      return ((_this$new_project$nam = this.new_project.name) === null || _this$new_project$nam === void 0 ? void 0 : _this$new_project$nam.length) && ((_this$new_project$dat3 = this.new_project.datasource) === null || _this$new_project$dat3 === void 0 ? void 0 : _this$new_project$dat3.id);
    },
    valid2() {
      return this.checkNonEmptyLines(this.queries) > 0 && this.checkNonEmptyLines(this.products) > 0;
    },
    emptyLines() {
      const lines = this.availableQueries - this.checkNonEmptyLines(this.queries);
      return lines > 0 ? lines : 0;
    }
  },
  watch: {
    tabs_model() {
      if (this.tabs_model.id === 1) {
        this.actions[0]["action"] = ({
          close
        }) => {
          close();
        };
        this.actions[1]["action"] = () => {
          this.tabs_model = this.tabs[this.tabs_model.id];
        };
        this.actions[1]["text"] = "Далее";
        this.actions[1]["disabled"] = !this.valid1;
      }
      if (this.tabs_model.id > 1 && this.tabs_model.id < 4) {
        this.actions[0]["action"] = () => {
          this.tabs_model = this.tabs[this.tabs_model.id - 2];
        };
        this.actions[1]["action"] = () => {
          this.tabs_model = this.tabs[this.tabs_model.id];
        };
        this.actions[1]["text"] = "Далее";
        if (this.tabs_model.id === 2) {
          this.actions[1]["disabled"] = !this.valid2;
        } else {
          this.actions[1]["disabled"] = false;
        }
      }
      if (this.tabs_model.id === 4) {
        this.actions[0]["action"] = () => {
          this.tabs_model = this.tabs[this.tabs_model.id - 3];
        };
        this.actions[1]["action"] = ({
          close
        }) => {
          this.submit({
            close
          });
        };
        this.actions[1]["loading"] = this.loading;
        this.actions[1]["text"] = "Запустить мониторинг";
        this.actions[1]["disabled"] = !this.validLast;
      }
    },
    valid1() {
      if (this.tabs_model.id === 1) {
        this.actions[1]["disabled"] = !this.valid1;
      }
    },
    valid2() {
      if (this.tabs_model.id === 2) {
        this.actions[1]["disabled"] = !this.valid2;
      }
    }
  },
  components: {
    LinedTextarea,
    AppSteps,
    Tooltip
  }
};