import "core-js/modules/esnext.iterator.map.js";
import LineChart from "@/components/charts/LineChart.vue";
import colors from "@/utils/colors";
import numbers from "@/utils/numbers";
export default {
  props: ["title", "info", "infoMin", "color", "chart"],
  data() {
    return {
      colors,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
          yAxes: [{
            ticks: {
              display: false
            },
            gridLines: {
              tickMarkLength: 0,
              display: false
            }
          }],
          xAxes: [{
            ticks: {
              display: false
            },
            gridLines: {
              display: false
            }
          }]
        },
        tooltips: {
          enabled: false
        },
        layout: {
          padding: {
            top: 8
          }
        }
      },
      refresh: 0
    };
  },
  components: {
    LineChart
  },
  mounted() {},
  computed: {
    chartData() {
      if (!this.chart || !this.chart.length) {
        return null;
      }
      const chart = this.chart;
      return {
        labels: chart.map((data, i) => i + 1),
        datasets: [{
          borderColor: this.colors[this.color].color,
          borderWidth: 2,
          pointBorderColor: "transparent",
          pointRadius: 0,
          data: chart
        }]
      };
    },
    formatInfo() {
      const number = Number(this.info);
      if (typeof number !== "number") {
        return number;
      }
      //!&: 2022-02-22
      if (number === -1) {
        return 0;
      }
      return numbers.format(number, "ru", 1000000);
    }
  },
  watch: {
    chart() {
      this.refresh++;
    }
  }
};