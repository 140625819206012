/**
 * Table actions can be rendered separately from main DataTable component
 * o.e. for complex layouts, when slots is not a solution
 * Pass same id property as in DataTable component to make it work
 */
export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  }
};