import "core-js/modules/es.array.push.js";
import ModalAddProject from "@/components/modals/ModalAddProject";
export default {
  props: {
    small: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {
    onCreateProject() {
      this.$access.hasOrThrow("seo");
      this.$refs.modalAddProject.open();
    },
    onAdded() {
      if (this.$route.name === "PositionMonitoring") {
        window.document.location.reload();
      } else {
        this.$router.push({
          name: "PositionMonitoring"
        });
      }
    }
  },
  components: {
    ModalAddProject
  }
};