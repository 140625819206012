import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import NewFeature from "@/components/NewFeature.vue";
export default {
  components: {
    NewFeature
  },
  model: {
    prop: "modelValue",
    event: "change"
  },
  props: ["tabs", "modelValue", "routeParam"],
  data() {
    return {
      idActiveTab: null
    };
  },
  computed: {},
  methods: {},
  watch: {
    tabs() {
      if (!this.tabs.find(i => i.id === this.idActiveTab)) {
        this.idActiveTab = this.tabs[0].id;
      }
    },
    modelValue: {
      immediate: true,
      handler(v) {
        this.idActiveTab = (v === null || v === void 0 ? void 0 : v.id) || this.tabs[0].id;
      }
    },
    "$route.params": {
      immediate: true,
      handler(params) {
        if (!this.routeParam) {
          return;
        }
        const paramValue = params[this.routeParam];
        if (!paramValue) {
          return;
        }
        const tab = this.tabs.find(i => i.id === paramValue);
        if (!tab) {
          return;
        }
        this.idActiveTab = tab.id;
      }
    },
    idActiveTab: {
      immediate: true,
      handler(id) {
        if (id && this.routeParam && this.$route.params[this.routeParam] !== id) {
          const method = this.$route.params[this.routeParam] ? "push" : "replace";
          this.$router[method]({
            ...this.$route,
            params: {
              ...this.$route.params,
              [this.routeParam]: id
            }
          }, () => {
            this.$emit("change", this.tabs.find(i => i.id === id));
          }, () => {
            this.$emit("change", this.tabs.find(i => i.id === id));
          });
        } else {
          this.$emit("change", this.tabs.find(i => i.id === id));
        }
      }
    }
  }
};