var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('SeoWrapper', [!_vm.pageIsLoaded ? _c('div', [!_vm.dataCollection ? _c('report-page', [_c('router-link', {
    staticClass: "back-link",
    attrs: {
      "to": '/position-monitoring'
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/icons/back.svg'),
      "alt": "Back"
    }
  }), _c('span', {
    staticClass: "back"
  }, [_vm._v("к списку проектов")])]), _c('main-title', {
    scopedSlots: _vm._u([{
      key: "action",
      fn: function () {
        return [_c('div', {
          staticClass: "select"
        }, [_c('button', {
          staticClass: "action-button btn-outline btn-edit-project",
          on: {
            "click": _vm.onEdit
          }
        }, [_c('span', [_vm._v("Редактировать проект")]), _c('img', {
          staticClass: "btn-toggle__img",
          attrs: {
            "src": require("../assets/images/icons/settings.png"),
            "alt": "Settings"
          }
        })])])];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('div', {
          staticClass: "highlights__filters"
        }, [_vm.projects_selected_items && _vm.projects_selected_items.length > 0 ? _c('app-select', {
          staticClass: "highlights__select",
          attrs: {
            "items": _vm.projects_selected_items,
            "absoluteTitle": "Список проектов",
            "fullwidth": ""
          },
          model: {
            value: _vm.selectedProject,
            callback: function ($$v) {
              _vm.selectedProject = $$v;
            },
            expression: "selectedProject"
          }
        }) : _vm._e(), _c('div', {
          staticClass: "report-settings"
        }, [_vm.calendar && _vm.projectCalendarBoundaries ? _c('range-calendar', {
          attrs: {
            "boundaries": _vm.projectCalendarBoundaries
          },
          model: {
            value: _vm.calendar,
            callback: function ($$v) {
              _vm.calendar = $$v;
            },
            expression: "calendar"
          }
        }) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }], null, false, 494778171)
  }, [_vm._v(" " + _vm._s(_vm.selectedProject.title) + " ")]), _vm.reportSettings ? _c('entity-charts', {
    attrs: {
      "cells": _vm.cells,
      "loader-config": _vm.reportSettings,
      "loader": _vm.loadOverview,
      "period": _vm.calendar
    }
  }) : _vm._e(), _c('main-title', {
    staticClass: "highlights__cards__title"
  }, [_vm._v(" Запросы и позиции ")]), Object.keys(_vm.selectedTableType).length > 0 ? _c('div', {
    staticClass: "mt32",
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_vm.table_types && _vm.table_types.length > 0 ? _c('app-select', {
    staticClass: "highlights__select",
    attrs: {
      "items": _vm.table_types,
      "width": "220"
    },
    model: {
      value: _vm.selectedTableType,
      callback: function ($$v) {
        _vm.selectedTableType = $$v;
      },
      expression: "selectedTableType"
    }
  }) : _vm._e(), _c('Tooltip', {
    attrs: {
      "position": "top right"
    }
  }, [_vm._v(" Выбор режима просмотра отчетов. “По товарам” - группировка запросов и позиций по товарам (удобно, когда в проекте большое количество запросов и товаров), “Списком” - облегченный режим, отображаются все запросы проекта и позиции всех товаров (удобно, когда в проекте 1 товар или нужно оценить позиции в целом). ")])], 1), _vm.selectedTableType.id === 30 && _vm.yourSkuItems && _vm.yourSkuItems.length > 0 ? _c('app-select', {
    staticClass: "highlights__select",
    staticStyle: {
      "margin-left": "30px"
    },
    attrs: {
      "items": _vm.yourSkuItems,
      "search": true,
      "absoluteTitle": "Выберите ваш товар",
      "full-width": ""
    },
    model: {
      value: _vm.selectedYourSku,
      callback: function ($$v) {
        _vm.selectedYourSku = $$v;
      },
      expression: "selectedYourSku"
    }
  }) : _vm._e(), _vm.selectedTableType.id === 30 && _vm.otherSkuItems && _vm.otherSkuItems.length > 0 ? _c('app-select', {
    staticClass: "highlights__select",
    staticStyle: {
      "margin-left": "30px"
    },
    attrs: {
      "items": _vm.otherSkuItems,
      "search": true,
      "absoluteTitle": "Товар конкурента",
      "full-width": ""
    },
    model: {
      value: _vm.selectedOtherSku,
      callback: function ($$v) {
        _vm.selectedOtherSku = $$v;
      },
      expression: "selectedOtherSku"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "table-filters",
    staticStyle: {
      "margin-left": "auto",
      "display": "flex"
    }
  }, [_vm.selectedTableType.id === 20 ? _c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId()
    }
  }) : _vm._e(), _c('button', {
    staticClass: "action-button btn-outline btn-edit-project",
    staticStyle: {
      "margin-left": "16px"
    },
    on: {
      "click": _vm.onChangeDatesOrder
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.datesOrderText))])])], 1)], 1) : _vm._e(), _vm.selectedTableType.id === 20 ? _c('div', {
    staticClass: "mt32"
  }, [_vm.reportSettings ? _c('Project-list-table', {
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings,
      "paginatable": true,
      "configurable": true,
      "stickyColumns": 3,
      "hasSkuColumn": true
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.selectedTableType.id === 10 && _vm.yourSkuItems && _vm.yourSkuItems.length > 0 ? _c('div', {
    staticClass: "mt32"
  }, [_vm.reportSettings ? _c('Products-queries-report', {
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings,
      "currentProject": _vm.currentProject
    }
  }) : _vm._e()], 1) : _vm._e(), _c('modal-edit-project', {
    ref: "ModalEditProject"
  })], 1) : _c('div', [_c('data-loading', {
    attrs: {
      "title": 'Сейчас мы собираем позиции по вашему проекту',
      "image": require('@/assets/images/monitoring-wireframe.png'),
      "text1": 'Начали сбор данных по указанным поисковым запросам. Данные в сервисе обновляются ежедневно - информация по вашим позициям появится завтра.',
      "text2": 'Мы также отправим вам уведомление на email, когда данные будут готовы!'
    }
  })], 1)], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };